import React, { useState, useEffect, useRef } from "react";
import {  useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import data from "./userData.json";
import "./login.css";

const Login = () => {
  const toast = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [allEmail, setAllEmail] = useState([]);
  // const [animation,setAnimation]=useState(false)

  const navigate = useNavigate();



  useEffect(() => {
    setAllEmail(data);
  }, []);

  const show = () => {
    toast.current.show({ severity: 'success', summary: 'Logged In Successfully' });
  };

  const replace = () => {
    toast.current.replace({ severity: 'error', summary: 'Failed to Log In' });
    const usernameInput = document.getElementById('username');
    const passwordInput = document.getElementById('password');
    const loginHeader = document.querySelector('.login-header');
    const loginBtn = document.querySelector('.login-btn');
    const processingSpinner = document.querySelector('.processing-spinner');

    if (usernameInput && passwordInput && loginHeader && loginBtn && processingSpinner) {
      usernameInput.classList.remove('animate-envelope', 'hide-elements');
      passwordInput.classList.remove('animate-envelope', 'hide-elements');
      loginHeader.classList.remove('hide-elements');
      loginBtn.classList.remove('hide-elements');
      processingSpinner.classList.remove('show-spinner');
    }
  };

  const animateEnvelope = () => {
    const usernameInput = document.getElementById('username');
    const passwordInput = document.getElementById('password');
    const envelopeWrapper = document.querySelector('.envelope-wrapper');
    const loginHeader = document.querySelector('.login-header');
    const loginBtn = document.querySelector('.login-btn');
    const processingSpinner = document.querySelector('.processing-spinner');

    if (usernameInput && passwordInput && envelopeWrapper && loginHeader && loginBtn && processingSpinner) {
      usernameInput.classList.add('animate-envelope');
      passwordInput.classList.add('animate-envelope');

      setTimeout(() => {
        envelopeWrapper.style.transform = 'translate(-90%, -90%) scale(1)';
        envelopeWrapper.classList.add('animate-mail');
      }, 1000);

      setTimeout(() => {
        processingSpinner.classList.add('show-spinner');
      }, 3000);
    }
  };

  const handle = (e) => {
    let roles=null
    e.preventDefault();
    animateEnvelope();
    let tt = "false";
    for (let i = 0; i < allEmail.allData.length; i++) {
      if (allEmail.allData[i].email === email && allEmail.allData[i].pass === password) {
        let z = allEmail.allData[i].role;
        setRole(z);
        roles=z
        tt = "true";
        break;
      }
    }
    if (tt === "true") {
      setTimeout(() => {
        show();
      }, 4000);

    } else {
      setTimeout(() => {
        replace();
        setEmail("");
        setPassword("");
      }, 4000);
    }
      
    
    localStorage.setItem("emailCALI",email)
    localStorage.setItem("roleCALI",roles)
    if (roles === "executive") { 
      setTimeout(() => {
        navigate("/executive");
      }, 5000); // Adjust the timing as needed
    } else if (roles === "licenseAdmin") {
      setTimeout(() => {
        navigate("/licenseAdmin");
      }, 5000); // Adjust the timing as needed
    } else if (roles === "systemAdmin") {
      setTimeout(() => {
        navigate("/systemAdmin");
      }, 5000); // Adjust the timing as needed
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="container">
     
        <div className="login-box">
          <h1 className="login-header">Login</h1>
          <form onSubmit={handle}>
            <div className="form-group">
              <input type="text" id="username" className="inputbox" placeholder="Username" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className="form-group">
              <input type="password" id="password" className="inputbox" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button type="submit" className="login-btn"><i className="fas fa-lock"></i></button>
          </form>
          <div className="envelope-wrapper">
            <div className="envelope"></div>
            <div className="envelope-lid"></div>
          </div>
          <div className="processing-spinner">
            <i className="fas fa-at"></i>
          </div>
        </div>
      </div>
       
    </>
  );
};

export default Login;
