import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

const AddProducts = () => {
  const [productData, setProductData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [edit, setEdit] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const toast = useRef(null);


  const reject = () => {
    debugger;
      setDeleteConfirm(false)
      toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 2000 });
  }

  useEffect(() => {
    GetAllProduct();
  }, []);


  const GetAllProduct = () => {
    axios.get('http://34.195.147.35:5000/api/Product')
      .then(response => {
        setProductData(response.data.result);
        setFilteredProducts(response.data.result);
        console.log("All Products are :", response.data.result);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const Post = () => {
    setVisible(true);
    setProductName("");
    setProductDescription("");
    setIsActive(false);
    setEdit(false)
  }

  const PostTheProduct = (e) => {
    e.preventDefault();
    setVisible(false);
    axios.post('http://34.195.147.35:5000/api/Product', {
      "productName": productName,
      "productDescription": productDescription,
      "isActive": isActive || false
    })
      .then(response => {
        console.log('Response:', response.data);
        GetAllProduct(); // Fetch products again after adding a new one
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const DeleteTheProduct = async (productId) => {
    try {
      const response = await axios.delete(`http://34.195.147.35:5000/api/Product/${productId}`);
      console.log('Product deleted successfully:', response.data);
      setFilteredProducts(filteredProducts.filter(product => product.productId !== productId));
      toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Item deleted successfully', life: 2000 });    // getting toast message if deleted
      GetAllProduct()

    } catch (error) {
      console.error('Error deleting product:', error);
    }
    setDeleteConfirm(false);
  };

  const EditTheProduct = (product) => {
    setVisible(true);
    setProductName(product.productName);
    setProductDescription(product.productDescription);
    setIsActive(product.isActive);
    setEditIndex(product.productId); 
    setEdit(true); 
  }
  
  const updateTheProduct = async (e) => {
    e.preventDefault();
    setVisible(false);
    try {
      const updatedProduct = {
        productId: editIndex, // Use editIndex as the productId
        productName: productName,
        productDescription: productDescription,
        isActive: isActive || false
      };
      const response = await axios.put(`http://34.195.147.35:5000/api/Product/products/${editIndex}`, updatedProduct); // Pass editIndex as the productId in the URL
      console.log('Product updated successfully:', response.data);
      GetAllProduct(); 
    } catch (error) {
      console.error('Error updating product:', error);
    }
  }
  

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredResults = productData.filter(item => {
      const productId = String(item.productId);
      return productId.toLowerCase().includes(searchTerm) || item.productName.toLowerCase().includes(searchTerm);
    });
    setFilteredProducts(filteredResults);
  };

  return (
    <>
         <Toast ref={toast} />
      <h1 className="Create_Header">
        <Link to="/licenseAdmin" className=".for_pointer" style={{ color: "white", textDecoration: "none" }}>
          <i className="pi pi-arrow-left  back_arrow" style={{ fontSize: '1.5rem' }}></i>
        </Link> List of all Products
      </h1>

      <div className="Add_Product_flexing">
        <button onClick={Post} className=".for_pointer buttonTheme">Add Product</button>
        
        <div>
        <i className="pi pi-search search_icon" style={{ fontSize: '1.3rem' }}></i>
       
        <input
          className="search-bar-container"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        /> 
        </div>
        
      </div>

      <table className="Add_Product_Table">
        <thead>
          <tr className="Add_Product_Tr" >
            <th className="Add_Product_Th">Product ID</th>
            <th>Product Name</th>
            <th>Product Description</th>
            <th>Product Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredProducts.map((value, key) => {
            return (

              <tr className="Add_Product_Tr" key={key}>
                <td className="Add_Product_Td">{value.productId}</td>
                <td className="Add_Product_Td">{value.productName}</td>
                <td className="Add_Product_Td">{value.productDescription}</td>
                {value.isActive ? (<td>true</td>) : (<td>false</td>)}

                <td>
                  <button onClick={() => { EditTheProduct(value) }} style={{ margin: "3px", marginLeft: "70px", marginRight: "20px" }} className="buttonTheme">Edit</button>
                  <button onClick={() => { setDeleteProductId(value.productId); setDeleteConfirm(true); }} className="buttonTheme">Delete</button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Dialog header={<React.Fragment>Add user <i className="pi pi-times cross_icon2" onClick={() => setVisible(false)}></i> </React.Fragment>}
        visible={visible}
        style={{ width: '35vw' }}
        onHide={() => setVisible(false)}
        id="popup_box"
      >
        <form onSubmit={edit ? updateTheProduct : PostTheProduct}>
          <div id="userForm">
            <label htmlFor="productName">Product Name:</label>
            <input
              type="text"
              id="productName"
              value={productName}
              onChange={(event) => setProductName(event.target.value)}
            />

            <label htmlFor="productDescription">Product Description:</label>
            <textarea
              id="productDescription"
              value={productDescription}
              onChange={(event) => setProductDescription(event.target.value)}
            />
          <div style={{display:"flex"}}>
          <span>
          <label htmlFor="isActive">Is Active: </label> 
          </span>
          <span>
          <input
           type="checkbox"
           id="isActive"
           checked={isActive}
           onChange={(event) => setIsActive(event.target.checked)}/> 
          </span>
          </div>
           
           
           
            
          </div>

         
                
           
          {edit ? <button type="submit" className="add_update_button buttonTheme" >Update</button> : <button type="submit" className="add_update_button buttonTheme" >Submit</button>}
        </form>
      </Dialog>

      <ConfirmDialog
        visible={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        message="Do you want to delete this record?"
        header="Delete Confirmation"
        icon="pi pi-info-circle"
        accept={() => DeleteTheProduct(deleteProductId)}
        reject={reject}
      />
    </>
  )
}

export default AddProducts;
