import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

const AddCustomers = () => {
  const [customerData, setCustomerData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [domain, setDomain] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [edit, setEdit] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
    
  const toast = useRef(null);

  const reject = () => {
    setDeleteConfirm(false)
    toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 2000 });
  }

  useEffect(() => {
    getAllCustomers();
  }, []);

  const getAllCustomers = () => {
    axios.get('http://34.195.147.35:5000/api/Customer')
      .then(response => {
        setCustomerData(response.data.result);
        setFilteredCustomers(response.data.result);
        console.log("kkkkkkk",response.data.result)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const postCustomer = () => {
    setVisible(true);
    setContactNumber("")
    setCustomerAddress("")
    setCustomerName("")
    setEmailAddress("")
    setOrganizationName("")
    setIsActive(false)
    setDomain("")
    setEdit(false)
  }

  const postTheCustomer = (e) => {
    e.preventDefault();
    setVisible(false);
    axios.post('http://34.195.147.35:5000/api/Customer', {
      "customerName": customerName,
      "customerAddress": customerAddress,
      "organizationName": organizationName,
      "domain": domain,
      "contactNumber": contactNumber,
      "emailAddress": emailAddress,
      "isActive": isActive || false
    })
      .then(response => {
        getAllCustomers();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const deleteCustomer = async (customerId) => {
    try {
      await axios.delete(`http://34.195.147.35:5000/api/Customer/${customerId}`);
      setFilteredCustomers(filteredCustomers.filter(customer => customer.customerID !== customerId))
      toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Item deleted successfully', life: 2000 });
    } catch (error) {
      console.error('Error deleting customer:', error);
    }
    setDeleteConfirm(false);
  };
  

  const editCustomer = (customer) => {
    debugger;
    setVisible(true);
    setCustomerName(customer.customerName);
    setCustomerAddress(customer.customerAddress);
    setOrganizationName(customer.organizationName);
    setDomain(customer.domain);
    setContactNumber(customer.contactNumber);
    setEmailAddress(customer.emailAddress);
    setIsActive(customer.isActive);
    setEditIndex(customer.customerID);
    setEdit(true);
  }
  
  const updateCustomer = async (e) => {
    e.preventDefault();
    setVisible(false);
    try {
      const updatedCustomer = {
        customerID: editIndex,
        customerName: customerName,
        customerAddress: customerAddress,
        organizationName: organizationName,
        domain: domain,
        contactNumber: contactNumber,
        emailAddress: emailAddress,
        isActive: isActive 
      };
  
      if (!isActive) {
        updatedCustomer.isActive = false;
      }
  
      await axios.put(`http://34.195.147.35:5000/api/Customer/UpdateCustomerData`, updatedCustomer);
      getAllCustomers();
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredResults = customerData.filter(item => {
      const customerID = String(item.customerID);
      return customerID.toLowerCase().includes(searchTerm) || item.customerName.toLowerCase().includes(searchTerm);
    });
    setFilteredCustomers(filteredResults);
  };
  
  return (
    <>
      <Toast ref={toast} />
      <h1 className="Create_Header" > 
        <Link to="/licenseAdmin" className=".for_pointer" style={{color:"white",textDecoration:"none"}} > <i className="pi pi-arrow-left  back_arrow" style={{ fontSize: '1.5rem' }}></i> </Link> List of all Customer</h1>
      
      <div className="Add_Product_flexing">
      <button onClick={postCustomer} className="for_pointer buttonTheme">Add Customer</button>

      <div>
      <i className="pi pi-search search_icon" style={{ fontSize: '1.3rem' }}></i>
        <input
        
          className="search-bar-container"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
       
         
      </div>

      <table className="Add_Product_Table">
  <thead>
    <tr className="Add_Product_Tr">
      <th className="Add_Product_Th">Customer ID</th>
      <th>Customer Name</th>
      <th>Customer Address</th>
      <th>Organization Name</th>
      <th>Domain</th>
      <th>Contact Number</th>
      <th>Email Address</th>
      <th>Active Status</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    
    {filteredCustomers.map((value, key) => {
      return (
        <tr className="Add_Product_Tr" key={key}>
          <td className="Add_Product_Td" >{value.customerID}</td>
          <td className="Add_Product_Td">{value.customerName}</td>
          <td className="Add_Product_Td">{value.customerAddress}</td>
          <td className="Add_Product_Td">{value.organizationName}</td>
          <td className="Add_Product_Td">{value.domain}</td>
          <td className="Add_Product_Td">{value.contactNumber}</td>
          <td className="Add_Product_Td">{value.emailAddress}</td>
          {value.isActive ? (<td>true</td>) : (<td>false</td>)}
          <td>
            <button onClick={() => { editCustomer(value) }} style={{ margin: "3px", marginLeft: "-5px", marginRight: "20px" }} className="buttonTheme">Edit</button>
            <button onClick={() => { setDeleteCustomerId(value.customerID); setDeleteConfirm(true); }} className="buttonTheme">Delete</button>
          </td>
        </tr>
      )
    })}
  </tbody>
</table>


      <Dialog header={<React.Fragment>Add Customer <i className="pi pi-times cross_icon2" onClick={() => setVisible(false)}></i> </React.Fragment>}
        visible={visible}
        style={{ width: '35vw' }}
        onHide={() => setVisible(false)}
        id="popup_box"
      >
        <form onSubmit={edit ? updateCustomer : postTheCustomer} id="customerForm">
          <div id="userForm">
            <label htmlFor="customerName">Customer Name:</label>
            <input
              type="text"
              id="customerName"
              value={customerName}
              onChange={(event) => setCustomerName(event.target.value)}
            />

            <label htmlFor="customerAddress">Customer Address:</label>
            <textarea
              id="customerAddress"
              value={customerAddress}
              onChange={(event) => setCustomerAddress(event.target.value)}
            />

            <label htmlFor="organizationName">Organization Name:</label>
            <input
              type="text"
              id="organizationName"
              value={organizationName}
              onChange={(event) => setOrganizationName(event.target.value)}
            />

            <label htmlFor="domain">Domain:</label>
            <input
              type="text"
              id="domain"
              value={domain}
              onChange={(event) => setDomain(event.target.value)}
            />

            <label htmlFor="contactNumber">Contact Number:</label>
            <input
              type="text"
              id="contactNumber"
              value={contactNumber}
              onChange={(event) => setContactNumber(event.target.value)}
            />

            <label htmlFor="emailAddress">Email Address:</label>
            <input
              type="email"
              id="emailAddress"
              value={emailAddress}
              onChange={(event) => setEmailAddress(event.target.value)}
            />

             <div style={{display:"flex"}}>
          <span>
          <label htmlFor="isActive"> Active Status: </label> 
          </span>
          <span>
          <input
           type="checkbox"
           id="isActive"
           checked={isActive}
           onChange={(event) => setIsActive(event.target.checked)}/> 
          </span>
          </div>



          </div>
          {edit ? <button type="submit" className="add_update_button buttonTheme" >Update</button> : <button type="submit" className="add_update_button buttonTheme" >Submit</button>}
        </form>
      </Dialog>

      <ConfirmDialog
        visible={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        message="Do you want to delete this record?"
        header="Delete Confirmation"
        icon="pi pi-info-circle"
        accept={() => deleteCustomer(deleteCustomerId)}
        reject={reject}
      />
    </>
  )
}

export default AddCustomers;
