import React, { useEffect, useState } from "react";
import "./header.css"
import { useNavigate } from "react-router-dom";
import logo from "./logo__cali.png"
const Header=()=>{
    const [loginName,setLoginName]=useState("")
    const [headerDisplay,setHeaderDisplay]= useState("")
    const [profileDis, setProfileDis]=useState(1)
    const navigate= useNavigate()
    useEffect(()=>{  
       let m = localStorage.getItem('emailCALI')
       let n = m.split("@")[0]
       setLoginName(n)

       let d = localStorage.getItem('roleCALI')
       if(d==="executive"){
         setHeaderDisplay(" License Server Executive Dashboard")
       }
       if(d==="licenseAdmin"){
         setHeaderDisplay(" License Admin Dashboard")
       }
       if(d==="systemAdmin"){
        setHeaderDisplay(" System Administrator Dashboard")
       }
    },[])
      const handle=()=>{
        setProfileDis(profileDis+1)
        if(profileDis===4){
           setProfileDis(1)
        }
      }
      const logoutHandle=()=>{
        localStorage.removeItem("emailCALI")
        localStorage.removeItem("roleCALI")
        navigate("/")
      }

    return(
        <> 
           <div id="logout">
           {profileDis%2==0 ? 
               <section id="popup_main_div">
               <section className="popup_profile">
               <img src="https://t4.ftcdn.net/jpg/04/83/90/95/360_F_483909569_OI4LKNeFgHwvvVju60fejLd9gj43dIcd.jpg" width="20px" height="20px"  alt="profile"  />
                   <h4>{loginName}</h4>

               </section>

               <section className="popup_profile">
               <img src="https://static-00.iconduck.com/assets.00/logout-icon-2048x2048-libuexip.png" width="20px" height="20px"  alt="profile"  />
                   <h4 onClick={logoutHandle} >Logout</h4>

               </section>
               </section> : " "} 

           </div>
           

          <section  className= {profileDis%2==0 ? "executive_header2" : "executive_header"   }>
          <a href="https://cloudangles.com/" target="_blank" ><img src={logo} alt="logo" width="55px" height="55px" id="logo_img" /></a>  
         <h1 id="head_header">{headerDisplay}</h1>
          
  <img src="https://t4.ftcdn.net/jpg/04/83/90/95/360_F_483909569_OI4LKNeFgHwvvVju60fejLd9gj43dIcd.jpg" width="40px" height="30px" id="profile_image" alt="profile" onClick={handle} />  

        </section>  
       
        </>
    )
}
export default Header