import React from "react";
const Error=()=>{
    return(
        <>
         
        <div>
         <h1> 404 Error </h1>
         <h1> Nice try !!  but you can't access this URL </h1>
         </div>
        </>
    )
}
export default Error