import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Toast } from 'primereact/toast';
const Mapping = () => {
    const [productData, setProductData] = useState([]);
    const [featureData, setFeatureData] = useState([]);
    const [productFeature, setProductFeature] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [todos, setTodos] = useState([]);

    const toast = useRef(null);  
    
    

    const GetAllProduct = () => {
        axios.get('http://34.195.147.35:5000/api/Product')
            .then(response => {
                // Filter products where isActive is true
                const activeProducts = response.data.result.filter(product => product.isActive === true);
                setProductData(activeProducts);
                console.log("Active Products are:", activeProducts);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getAllFeatures = () => {
        axios.get('http://34.195.147.35:5000/api/Feature')
            .then(response => {
                const activeData = response.data.result.filter(filtering => filtering.isActive === true);
                setFeatureData(activeData);
                console.log("feature---", response.data.result);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getAllProductFeatures = () => {
        axios.get('http://34.195.147.35:5000/api/ProductFeature')
            .then(response => {
                setProductFeature(response.data.result);
                console.log("ProductFeature------", response.data.result);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        getAllFeatures();
        GetAllProduct();
        getAllProductFeatures();
    }, []);

    const handleProductChange = (event) => {
        debugger
        const productId = parseInt(event.target.value);
        const product = productData.find(product => product.productId === productId);
        setSelectedProduct(product);
    };

    const handleFeatureChange = (event) => {
        debugger;
        const { value, checked } = event.target;
        const featureId = parseInt(value);
        if (checked) {
            setSelectedFeatures([...selectedFeatures, featureId]);
        } else {
            setSelectedFeatures(selectedFeatures.filter(feature => feature !== featureId));
        }
    };

    const addTodo = () => {
        debugger;
        setSelectedFeatures("")
        setSelectedProduct("")
        if (selectedProduct.productId && selectedFeatures.length > 0) {
            const todo = {
                productId: selectedProduct.productId,
                productName: selectedProduct.productName,
                featureIds: selectedFeatures.join(", "),
                // featureNames: selectedFeatures.map(featureId => {
                //     const feature = featureData.find(feature => feature.featureId === featureId);
                //     return feature.featureName;
                // }).join(", ")
            };
            console.log("toooo------------",todo)
            // POST request to add the todo to ProductFeature
            axios.put('http://34.195.147.35:5000/api/ProductFeature', {
                "productId": todo.productId,
                "featureIds": todo.featureIds
            })
            .then(response => {
                console.log("Todo added successfully:", response.data);
                toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Mapping data is saved successfully ', life: 2000 });
                setTodos([...todos, todo]);
                getAllProductFeatures();
                
            })
            .catch(error => {
                console.error('Error adding todo:', error);
            });
        }
    };

    
         
    const editTodo = (productId) => {
        debugger;
        const product = productData.find(product => product.productId === productId);
        setSelectedProduct(product);
        
        let fea = [];
        for (let i = 0; i < productFeature.length; i++) {
            if (productFeature[i].productId === productId) {
                console.log("iii", productFeature[i]);
                fea = productFeature[i].featureId.split(",");
            }
        }
        
        setSelectedFeatures(fea.map(featureId => parseInt(featureId)));
    };
    
    

    return (
        <>
            <Toast ref={toast} />
           
            <h1 className="Create_Header" >
        <Link to="/licenseAdmin" className=".for_pointer" style={{ color: "white", textDecoration: "none" }}>
          <i className="pi pi-arrow-left  back_arrow" style={{ fontSize: '1.8rem' }} id="icon_mapping"></i>
        </Link> Map Products & Features
      </h1>


      <div className="flexing_mapping">

        <div className="product_mapping">  
        <h2>Products</h2>
        {productData.map((product) => {
    return (
        <div key={product.productId} className="flex align-items-center">
            <RadioButton inputId={product.productId} name="category" value={product.productId} onChange={handleProductChange} checked={selectedProduct.productId === product.productId} />
            <label htmlFor={product.productId} className="ml-2 forSpace" >{`  ${product.productName}`}</label>
        </div> 
    );
})}  
                {/* {productData.map(product => (
                    <div key={product.productId}>
                        <input
                            type="radio"
                            id={product.productId}
                            name="product"
                            value={product.productId}
                            checked={selectedProduct.productId === product.productId}
                            onChange={handleProductChange}
                        />
                        <label htmlFor={product.productId}>{product.productName}</label>
                    </div>
                ))} */}

            </div>

                             <button onClick={addTodo} id="Add_mapping" className="buttonTheme"> Save </button>

            <div className="product_mapping">
                <h2>Features</h2>
                {featureData.map((feature) => (
                   
    <div key={feature.featureId} className="flex align-items-center" id="checkbox_mapping">
       
        <Checkbox
            inputId={feature.featureId}
            name="category"
            value={feature.featureId}
            onChange={handleFeatureChange}
            checked={selectedFeatures.includes(feature.featureId)} // Check if feature ID is included in selectedFeatures
        />
        <label htmlFor={feature.featureId} className="ml-2 forSpace">{feature.featureName}</label>
    </div>
))}
                {/* {featureData.map(feature => (
                    <div key={feature.featureId}>
                        <input
                            type="checkbox"
                            id={feature.featureId}
                            name={feature.featureName}
                            value={feature.featureId}
                            checked={selectedFeatures.includes(feature.featureId)}
                            onChange={handleFeatureChange}
                        />
                        <label htmlFor={feature.featureId}>{feature.featureName}</label>
                    </div>
                ))} */}

                
                  </div>
      

            

            </div>



{/* ----------------------------------------   TABLE   ------------------------------------------------------------ */}

<div className="table-container">
    <table className="custom-table">
        <thead className="table-head">
            <tr>
                <th className="table-header">Product ID</th>
                <th className="table-header">Product Name</th>
                <th className="table-header">Feature IDs</th>
                <th className="table-header">Feature Names</th>
                <th className="table-header">Action</th>
            </tr>
        </thead>
        <tbody className="table-body">
            { productFeature !== null && productFeature
                .sort((a, b) => a.productId - b.productId) // Sort by productId
                .map((item, index) => (
                    <tr key={index} className="Add_Product_Tr">
                        <td className="Add_Product_Td">{item.productId}</td>
                        <td className="Add_Product_Td">{productData.find(product => product.productId === item.productId)?.productName}</td>

                        <td className="Add_Product_Td">{item.featureId}</td>
                        <td className="Add_Product_Td" >{item.featureId && item.featureId.split(",").map(featureId => {
                            const feature = featureData.find(feature => feature.featureId === parseInt(featureId));
                            return feature ? feature.featureName : '';
                        }).join(", ")}</td>

                        <td className="table-cell"><button onClick={() => editTodo(item.productId,item.featureId)} className="buttonTheme table-action"> Edit </button></td>
                    </tr>
                ))}
        </tbody>
    </table>
</div>


        </>
    );
}

export default Mapping;
