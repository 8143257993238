import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import "./executive.css"
import Header from "../Header/header";
import { Chart } from 'primereact/chart';
import {useNavigate} from "react-router-dom"    
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import * as XLSX from 'xlsx';

const Executive=()=>{
  // const [customerData, setCustomerData] = useState([]);
  // const [productData, setProductData] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  const [chartData2, setChartData2] = useState({});
  const [chartOptions2, setChartOptions2] = useState({});

  const [licenseData,setLicenseData]=useState([])


  const [customerData, setCustomerData] = useState([]);
  const [customerName,setCustomerName] =useState([])

    const [productData, setProductData] = useState([]);
    const [featureData, setFeatureData] = useState([]);
    const [productFeature, setProductFeature] = useState([]);


  const [localVisible,setLocalVisible]= useState(false)
  const navigate=useNavigate()

  const [licenseLoading, setLicenseLoading] = useState(false);
  const [licenseMessage, setLicenseMessage] = useState('');
  const [keyLoading, setKeyLoading] = useState(false);
  const [keyMessage, setKeyMessage] = useState('');
  const toast = useRef(null);
  
  
  const downloadExcel = () => {
    const wb = XLSX.utils.book_new();

    const productWs = XLSX.utils.json_to_sheet(productData);
    XLSX.utils.book_append_sheet(wb, productWs, "Products");

    const customerWs = XLSX.utils.json_to_sheet(customerData);
    XLSX.utils.book_append_sheet(wb, customerWs, "Customers");

    const featureWs = XLSX.utils.json_to_sheet(featureData);
    XLSX.utils.book_append_sheet(wb, featureWs, "Features");

    const ProductFeatureWs = XLSX.utils.json_to_sheet(productFeature);
    XLSX.utils.book_append_sheet(wb, ProductFeatureWs, "ProductFeature");

    console.log("Workbook:", wb);

    XLSX.writeFile(wb, "LicenseData.xlsx");
  }



    useEffect(() => {
      
      const currentURL = window.location.href;

        const ll = localStorage.getItem("roleCALI");
        // console.log("llllllllll", currentURL, ll);
        if (ll && currentURL.includes(ll)) {
          setLocalVisible(true);
         

          const fetchData = async () => {
            const customerResponse = await axios.get('http://34.195.147.35:5000/api/Customer');
            const productResponse = await axios.get('http://34.195.147.35:5000/api/Product');
            const featureResponse = await axios.get('http://34.195.147.35:5000/api/Feature');
            const productfeatureResponse = await axios.get('http://34.195.147.35:5000/api/ProductFeature')
            const license = await axios.get('http://34.195.147.35:5000/api/License')



           
            setProductData(productResponse.data.result);
            setFeatureData(featureResponse.data.result);
            setProductFeature(productfeatureResponse.data.result);

            setCustomerData(customerResponse.data.result);
            setLicenseData(license.data.result);
            
            // console.log("license ---------", license.data.result)
             console.log("customer---------", customerResponse.data.result)
            // console.log("product----------", productResponse.data.result )
            const customerNames = customerResponse.data.result.map(customer => customer.customerName);
            const customerIdone = customerResponse.data.result.map(customer => customer.customerID);
            // console.log("Idssssssss", customerIdone)
            
            let arr1=[]
            for(let i=0;i<customerIdone.length;i++){
              let count=0
              for(let j=0;j<license.data.result.length;j++){
                if(customerIdone[i]===license.data.result[j].customerId){
                     count++
                     
                }
               
              }
              arr1.push(count)
            }

            const productNames = productResponse.data.result.map(product => product.productName);
            const productIdone = productResponse.data.result.map(product => product.productId);
          
            let arr2=[]
            for(let k=0;k<productIdone.length;k++){
              let count2=0
              for(let l=0;l<license.data.result.length;l++){
                if(productIdone[k]===license.data.result[l].productId){
                     count2++
                     
                }
               
              }
              arr2.push(count2)
            }
            
            console.log("arr222222222222",arr2)

             
            const data = {
              labels: customerNames ,
              datasets: [
                {
                  label: 'Sales by customer',
                  data: arr1,
                  backgroundColor: [
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(75, 192, 177, 0.5)',
                    'rgba(99, 99, 235, 0.2)',
                    'rgba(153, 102, 255, 0.2)'

                  ],
                  borderColor: [
                    'rgb(255, 159, 64)',
                    'rgb(75, 192, 192)',
                    'rgb(54, 162, 235)',
                    'rgb(153, 102, 255)'
                  ],
                  borderWidth: 1
                }
              ]
            };
            const options = {
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            };
    

            const data2 = {
              labels: productNames ,
              datasets: [
                {
                  label: 'Sales by product',
                  data: arr2,
                  backgroundColor: [
                    'rgba(99, 99, 235, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(75, 192, 177, 0.5)', 
                    'rgba(153, 102, 255, 0.2)'

                  ],
                  borderColor: [
                    'rgb(54, 162, 235)',
                    'rgb(255, 159, 64)',
                    'rgb(75, 192, 192)',
                    
                    'rgb(153, 102, 255)'
                  ],
                  borderWidth: 1
                }
              ]
            };
            const options2 = {
              scales: {
                y: {
                  beginAtZero: true
                }
              }
            };
      
          
           
            setChartData(data);
            setChartOptions(options);

            setChartData2(data2);
            setChartOptions2(options2);
    
            
          };
      
          fetchData();
        } else {
          navigate("/error")
        }
      }, [setLocalVisible]);

     
      const handleLicense = (e) => {
        setLicenseLoading(true);
        setLicenseMessage('Sending...');
        console.log("customerId :",e.customerId ,"productId :",e.productId )
        let customerIdIs = e.customerId;
        let productIdIs = e.productId;
        const url = `http://34.195.147.35:5001/api/ExchangeKey/Send_Encrypted_LicenseData/${customerIdIs}/${productIdIs}`;
    
        axios.post(url)
          .then(response => {
            console.log("License data sent successfully:", response.data);
            // alert("License sended successfully")
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'License sended successfully', life: 2000 });
          })
          .catch(error => {
            console.error('Error sending license data:', error);
            toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'Error in sending License', life: 2000 });
          }).finally(() => {
            setLicenseLoading(false);
            setLicenseMessage('');
          });
      };

       const handleKey=(e)=>{
        setKeyLoading(true);
    setKeyMessage('Sending...');
        console.log("customerId :",e.customerId ,"productId :",e.productId )
        let customerIdIs = e.customerId;
        let productIdIs = e.productId;
        const url =`http://34.195.147.35:5001/api/ExchangeKey/send-encrypted-key/${customerIdIs}/${productIdIs}`;
    
        axios.post(url)
          .then(response => {
            console.log("License data sent successfully:", response.data);
            toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Key sended successfully', life: 2000 });
          })
          .catch(error => {
            console.error('Error sending license data:', error);
            toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'Error in sending License', life: 2000 });
          }).finally(() => {
            setKeyLoading(false);
            setKeyMessage('');
          });
       }


    return(
        <>
           <Toast ref={toast} />
      {localVisible ? <div>
        <Header/>
    

    <main>
      <section className="summary-cards">
        <div className="summary-card1">
          <h2>Total Licenses</h2>
          <p className="total">{licenseData.length}</p>
        </div>
    
        <div className="summary-card2">
          <h2>Active Licenses</h2>
          <p className="total">{licenseData.length}</p>
        </div>
        
        <div className="summary-card3">
          <h2>Licenses by Customer </h2>
          <Chart type="bar" data={chartData} options={chartOptions} className="w-full md:w-30rem" />
         
         
        </div>
        <div className="summary-card4">
          <h2>Licenses by Product </h2>
          <Chart type="line" data={chartData2} options={chartOptions2} />
          
        </div>
      </section>
      
      
      <section className="license-table">
        <div className="flex_download">
        <h2>License Table</h2>

<button className="buttonTheme download_excel" onClick={downloadExcel}>Download Excel</button>

        </div>
      
        <div className="table-container">
            <table className="Add_Product_Table custom-table">
              <thead className="table-head">
                <tr className="Add_Product_Tr" >
                  <th className="Add_Product_Th table-header">License ID</th>
                  <th className="table-header"> product ID</th>
                  <th className="table-header"> Customer ID</th>
                  <th className="table-header"> Domain</th>
                  <th className="table-header"> IP Address </th>
                  <th className="table-header"> Start Date</th>
                  <th className="table-header"> End Date</th>
                  <th className="table-header"> Generate </th>
                </tr>
              </thead>
              <tbody>
                {licenseData.map((value, key) => {
                   let x= value.activationDate.split("T")[0]
                   let y= value.expirationDate.split("T")[0]
                  return (
                    <tr className="Add_Product_Tr" key={key}>
                      <td className="Add_Product_Td">{value.id}</td>
                      <td className="Add_Product_Td">{value.productId}</td>
                      <td className="Add_Product_Td">{value.customerId}</td>
                      <td className="Add_Product_Td">{value.domain}</td>
                      <td className="Add_Product_Td">{value.ip}</td>
                      <td className="Add_Product_Td">{x}</td>
                      <td className="Add_Product_Td">{y}</td>
                     
                      <td className="Add_Product_Td aa " >

                      <button className="buttonTheme" onClick={() => { handleKey(value) }} > Send key </button>
                              <Dialog visible={keyLoading} modal style={{ width: '150px', textAlign: 'center' }} onHide={() => { }}>
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                <div>{keyMessage}</div>
                              </Dialog>   
                      
                              <button className="buttonTheme" onClick={() => { handleLicense(value) }} > Send license</button>
                              <Dialog visible={licenseLoading} modal style={{ width: '150px', textAlign: 'center' }} onHide={() => { }}>
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                                <div>{licenseMessage}</div>
                              </Dialog>
                            
                            
                                     
                        
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
      </section>
    </main>
      </div> :""}
    
        </>
    )
}
export default Executive