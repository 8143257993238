import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

const ManageFeatures = () => {
  const [featureData, setFeatureData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [featureName, setFeatureName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [editFeatureId, setEditFeatureId] = useState(null);
  const [edit, setEdit] = useState(false);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteFeatureId, setDeleteFeatureId] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const toast = useRef(null);

  const reject = () => {
    setDeleteConfirm(false);
    toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 2000 });
  }

  useEffect(() => {
    getAllFeatures();
  }, []);

  const getAllFeatures = () => {
    axios.get('http://34.195.147.35:5000/api/Feature')
      .then(response => {
        setFeatureData(response.data.result);
        setFilteredFeatures(response.data.result);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const postFeature = () => {
    setVisible(true);
    setFeatureName("");
    setIsActive(false);
    setEdit(false);
  }

  const postTheFeature = (e) => {
    debugger
    e.preventDefault();
    setVisible(false);
    axios.post('http://34.195.147.35:5000/api/Feature', {
      "featureName": featureName,
      "isActive": isActive
    })
      .then(response => {
        getAllFeatures();
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const deleteFeature = async (featureId) => {
    try {
      await axios.delete(`http://34.195.147.35:5000/api/Feature/${featureId}`);
      setFilteredFeatures(filteredFeatures.filter(feature => feature.featureId !== featureId))
      toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Item deleted successfully', life: 2000 });
    } catch (error) {
      console.error('Error deleting feature:', error);
    }
  };

  const editFeature = (feature) => {
    setVisible(true);
    setFeatureName(feature.featureName);
    setIsActive(feature.isActive);
    setEditFeatureId(feature.featureId);
    setEdit(true);
  }

  const updateFeature = async (e) => {
    debugger
    e.preventDefault();
    setVisible(false);
    try {
      // const updatedFeature = {
      //   featureId: editFeatureId,
      //   featureName: featureName,

      //   isActive: isActive || false ,
      // };
      console.log("isssssss",isActive)
      await axios.put(`http://34.195.147.35:5000/api/Feature/UpdateFeatureData`, 
      {
      "featureId": editFeatureId,
      "featureName": featureName,
      "isActive" : isActive 
      } 
      );
      getAllFeatures();
    } catch (error) {
      console.error('Error updating feature:', error);
    }
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredResults = featureData.filter(item => {
      const featureId = String(item.featureId);
      return featureId.toLowerCase().includes(searchTerm) || item.featureName.toLowerCase().includes(searchTerm);
    });
    setFilteredFeatures(filteredResults);
  };

  return (
    <>
      <Toast ref={toast} />
      <h1 className="Create_Header">
        <Link to="/licenseAdmin" className=".for_pointer" style={{ color: "white", textDecoration: "none" }} > <i className="pi pi-arrow-left  back_arrow" style={{ fontSize: '1.5rem' }}></i> </Link> List of all Features</h1>
      <div className="Add_Product_flexing">
        <button onClick={postFeature} className="buttonTheme" >Add Feature</button>

        <div>
        <i className="pi pi-search search_icon" style={{ fontSize: '1.3rem' }}></i>
        <input
          className="search-bar-container"
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        </div>
       
      </div>

      <div className="table-container">
        <table className="Add_Product_Table custom-table">
          <thead className="table-head">
            <tr className="Add_Product_Tr" >
              <th className="Add_Product_Th table-header">Feature ID</th>
              <th className="table-header"> Feature Name</th>
              <th className="table-header">Active Status</th>
              <th className="table-header">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredFeatures.map((value, key) => {
              return (
                <tr className="Add_Product_Tr" key={key}>
                  <td className="Add_Product_Td">{value.featureId}</td>
                  <td className="Add_Product_Td">{value.featureName}</td>
                  {value.isActive ? (<td>true</td>) : (<td>false</td>)}
                  <td>
                    <button onClick={() => { editFeature(value) }} style={{ margin: "3px", marginLeft: "70px", marginRight: "20px" }} className="buttonTheme">Edit</button>
                    <button onClick={() => { setDeleteFeatureId(value.featureId); setDeleteConfirm(true); }} className="buttonTheme">Delete</button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <Dialog header={<React.Fragment>Add Feature <i className="pi pi-times cross_icon2" onClick={() => setVisible(false)}></i> </React.Fragment>}
        visible={visible}
        style={{ width: '35vw' }}
        onHide={() => setVisible(false)}
        id="popup_box"
      >
        <form onSubmit={edit ? updateFeature : postTheFeature} id="featureForm">
          <div id="userForm">
            <label htmlFor="featureName">Feature Name:</label>
            <input
              type="text"
              id="featureName"
              value={featureName}
              onChange={(event) => setFeatureName(event.target.value)}
            />
            <div style={{ display: "flex" }}>
              <span>
                <label htmlFor="isActive">Is Active: </label>
              </span>
              <span>
                <input
                  type="checkbox"
                  id="isActive"
                  checked={isActive}
                  onChange={(event) => setIsActive(event.target.checked)} />
              </span>
            </div>
          </div>
          {edit ? <button type="submit" className="add_update_button buttonTheme" >Update</button> : <button type="submit" className="add_update_button buttonTheme" >Submit</button>}
        </form>
      </Dialog>

      <ConfirmDialog
        visible={deleteConfirm}
        onHide={() => setDeleteConfirm(false)}
        message="Do you want to delete this record?"
        header="Delete Confirmation"
        icon="pi pi-info-circle"
        accept={() => deleteFeature(deleteFeatureId)}
        reject={reject}
      />
    </>
  )
}

export default ManageFeatures;
