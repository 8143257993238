import React, { useState,useEffect } from "react";
import "./licenseAdmin.css"
import Header from "../Header/header";
import license from "./Images/license.png"
import koelpin from "./Images/koelpin.png"
import wolf from "./Images/wolf.png"
import smart from "./Images/smartOffice.png"
import mlangle from "./Images/mlangle.png" 
import product from "./Images/product.jpg"
import customer from "./Images/customer.jpg"
import feature from "./Images/feature.jpg"
import mapping from "./Images/mapping.jpg"

import { Dialog } from 'primereact/dialog';
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom"                     
const LicenseAdmin=()=>{

  const [visible,setVisible]=useState(false) 
  const [count,setCount]=useState(1)
 
  const [localVisible,setLocalVisible]= useState(false)
  const navigate=useNavigate()
  

  useEffect(() => {
    const currentURL = window.location.href;
    const ll = localStorage.getItem("roleCALI");
    console.log("llllllllll", currentURL, ll);
    if (ll && currentURL.includes(ll)) {
      setLocalVisible(true);
    
    } else {
      navigate("/error")
    }
  }, [setLocalVisible]);
     

     const handleCountPrevious=()=>{
         if(count===1){
          setCount(1)
         }
         else{
          setCount(count-1)
         }
     }
   const handleCountNext=()=>{
        if(count===4){
          setCount(1)
          setVisible(false)
        }
        else{
          setCount(count+1)
        }
   }

     
    return(
        <>
        {localVisible ? <div>
          <Header/>
          

          <main>
            <div className="tile-container">
              <article className="tile create-license" id="createLicenseBtn">
                <Link to="/licenseAdmin/manageLicense" style={{textDecoration:"none",color:"white"}}>
                <img height="120" width="120" style={{borderRadius:"80%", borderBottom:"4px solid grey" }} src={license} alt="Create License" className="licenseAdmin_logo" />
                </Link>
                
                {/* <h3 onClick={()=>{setVisible(true)}} >Create License</h3> */}
                <h3> <Link to="/licenseAdmin/manageLicense" style={{textDecoration:"none",color:"white"}} > Create License </Link></h3>
              </article>
        
              <article className="tile create-license" id="createLicenseBtn">
                 <Link to="/licenseAdmin/manageProduct" style={{textDecoration:"none",color:"white"}} > <img height="150" width="150" src={product} alt="Create License" className="licenseAdmin_logo"/> </Link> 
                <h3  ><Link to="/licenseAdmin/manageProduct" style={{textDecoration:"none",color:"white"}} > Manage Product </Link></h3>
                
              </article>
        
              <article className="tile create-license" id="createLicenseBtn">
              <Link to="/licenseAdmin/manageCustomer" style={{textDecoration:"none",color:"white"}} > <img height="200" width="200" src={customer} alt="Create License" className="licenseAdmin_logo"/> </Link> 
                <h3  > <Link to="/licenseAdmin/manageCustomer" style={{textDecoration:"none",color:"white"}} > Manage Customer </Link></h3>
               
              </article>
        
              <article className="tile create-license" id="createLicenseBtn">
               
                <Link to="/licenseAdmin/manageFeature" style={{textDecoration:"none",color:"white"}} >  <img height="150" width="150" src={feature} alt="Create License" className="licenseAdmin_logo2" /> </Link> 
                <h3  > <Link to="/licenseAdmin/manageFeature" style={{textDecoration:"none",color:"white"}} > Manage Feature </Link></h3>
              </article>
        
              <article className="tile create-license" id="createLicenseBtn">
                 <Link to="/licenseAdmin/mapping" style={{textDecoration:"none",color:"white"}} > <img height="150" width="150" src={mapping} alt="Create License" className="licenseAdmin_logo"/> </Link> 
                <h3  ><Link to="/licenseAdmin/mapping" style={{textDecoration:"none",color:"white"}} > Map ProductFeature </Link></h3>   
              </article>
        
              <article className="tile">
                <i className="fas fa-cog"></i>
                <h3>Settings</h3>
              </article>
              
            </div>
        
            <div className="license-table">
              <h2>Customer Licenses</h2>
              <table id="licenseAdmin_table">
                <thead>
                  <tr id="licenseAdmin_table_row">
                    <th>Customer</th>
                    <th>mlops</th>
                    <th>llmops</th>
                    <th>SmartOfficeNxt</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Wolf - Crist
                    <span style={{marginLeft:"10px"}}>
                      <td className="licensed"></td>
                      <td className="unlicensed"></td>
                      <td className="unlicensed"></td>
                      </span>
                    </td>
                  
                  </tr>
        
        
                  <tr>
                    <td>Koelpin - Bauch
                      <span style={{marginLeft:"10px"}}>
                      <td className="unlicensed"></td>
                       <td className="licensed"></td>
                       <td className="licensed"></td>
                      </span>
                   
                    </td>
                   
                  </tr>
                 
                </tbody>
              </table>
            </div>
          </main>
        
          <Dialog header= { <React.Fragment> Add user <i className="pi pi-times cross_icon" onClick={() => setVisible(false)}> </i>  </React.Fragment> }
                visible={visible}
                style={{ width: '55vw' }}
                onHide={() => setVisible(false)}
                id="popup_box"
              >
                   
          <div >
         
          {count ===1 ? 
           <div className="wizard-step" id="selectCustomer">
           <h3>Select Customer</h3>
           <div className="customer-list">  
             <img src={koelpin} alt="ii" width="80px" height="60px" onClick={()=>{setCount(count+1)}}  />
             <img src={wolf} alt="ii"  width="80px" height="60px" onClick={()=>{setCount(count+1)}}  />
           </div>
        
         </div> : count ===2 ? 
          <div className="wizard-step" id="selectProduct" >
          <h3>Select Product</h3>
          <div className="product-list">  
          <img src={smart} alt="ii" width="80px" height="60px" onClick={()=>{setCount(count+2)}}   />
             <img src={mlangle} alt="ii"  width="80px" height="60px" onClick={()=>{setCount(count+2)}}  /> 
          </div>
        
        </div> : count ===3 ? 
         <div className="wizard-step" id="configureProduct" >
         <h3>Configure Product</h3>
         <div className="product-config">
           
         </div>
        
        </div> :
        <div className="wizard-step" id="confirmLicense" >
          <h3>Confirm License</h3>
          <div className="license-details">
           
          </div>
        </div> }
        
        
        
        
        
        
        
        </div>
        <div className="modal-footer">
          {count===1 ? " " : <button id="prevBtn" onClick={handleCountPrevious} >Previous</button> }
        
        <button id="nextBtn" onClick={handleCountNext}>Next</button>
        </div>
        
            </Dialog>
           
        </div>  : ""}
        
 
        </>
    )
}
export default LicenseAdmin