import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { RadioButton } from 'primereact/radiobutton';
import { Link } from "react-router-dom";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from 'primereact/floatlabel';
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
const AddLicense = () => {
    const [customerData, setCustomerData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [featureData, setFeatureData] = useState([]);
    const [productFeature, setProductFeature] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [selectedProduct, setSelectedProduct] = useState("");
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [filteredFeatures, setFilteredFeatures] = useState([]);

    const [ipAddress,setIpAddress]=useState("")
    const [domainName,setDomainName]=useState("")
    const [startDate,setStartDate]=useState(Date)
    const [endDate,setEndDate]=useState(Date)
    const [heartBeatIntervalDays,setheartBeatIntervalDays]=useState()
    const [featureswap,setFeatureSwap]= useState(false)
     
    const toast = useRef(null);

     const navigate = useNavigate()
    useEffect(() => {
        getAllCustomers();
        GetAllProduct();
        getAllFeatures();
        getAllProductFeatures();

        setheartBeatIntervalDays(4)
    }, []);

    const getAllCustomers = () => {
        axios.get('http://34.195.147.35:5000/api/Customer')
            .then(response => {
                const activeData = response.data.result.filter(filtering => filtering.isActive === true);
                setCustomerData(activeData);
                console.log("Customer -------", response.data.result )
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const GetAllProduct = () => {
        axios.get('http://34.195.147.35:5000/api/Product')
            .then(response => {
                const activeData = response.data.result.filter(filtering => filtering.isActive === true);
                setProductData(activeData);
                console.log("Product --------", response.data.result )
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getAllFeatures = () => {
        axios.get('http://34.195.147.35:5000/api/Feature')
            .then(response => {
                const activeData = response.data.result.filter(filtering => filtering.isActive === true);
                setFeatureData(activeData);
                console.log("Feature --------", response.data.result )
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getAllProductFeatures = () => {
        axios.get('http://34.195.147.35:5000/api/ProductFeature')
            .then(response => {
                setProductFeature(response.data.result);
                console.log(" ProductFeature --------", response.data.result )
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handleCustomerChange = (event) => {
        const SelectedCustomerId = parseInt(event.target.value);
        const customer = customerData.find(customer => customer.customerID === SelectedCustomerId);
        // setSelectedProduct(customer);
        setSelectedCustomer(customer);
    };

    const handleProductChange = (event) => {
        debugger
        const SelectedProductId = parseInt(event.target.value);
        const product = productData.find(product => product.productId === SelectedProductId);
        setSelectedProduct(product);
        let productFeatures = []

        for(let i=0;i<productFeature.length;i++){
            // console.log("fffffffff",productFeature[i].productId)
            // console.log("fff2222", SelectedProductId )

              if(productFeature[i].productId == SelectedProductId){
                productFeatures.push(productFeature[i].featureId)
                break;
              }
        }

        if (productFeatures) {
            let pp=[]
            // console.log("filtered------------iiii",productFeatures )
            let x= productFeatures[0].split(",")
            // console.log(x)
            for(let i=0;i<x.length;i++){
                for(let j=0;j<featureData.length;j++){
                    if(x[i]==featureData[j].featureId){
                        // console.log("ppp", featureData[j].featureName)
                        pp.push({"featureId":featureData[j].featureId , "featureName": featureData[j].featureName} )
                    }
                }
            }
             setFilteredFeatures(pp);
        } else {
            setFilteredFeatures([]);
        }
    };

    const handleFeatureChange = (event) => {
        const { name, value } = event.target;
        const featureId = parseInt(name);
        const propertyValue = value;
        const featureName = filteredFeatures.find(feature => feature.featureId === featureId)?.featureName || '';

        const existingFeatureIndex = selectedFeatures.findIndex(feature => feature.featureId === featureId);

        if (existingFeatureIndex !== -1) {
            const updatedFeatures = [...selectedFeatures];
            updatedFeatures[existingFeatureIndex].propertyValue = propertyValue;
            setSelectedFeatures(updatedFeatures);
        } else {
            setSelectedFeatures(prevFeatures => [...prevFeatures, { featureId, featureName, propertyValue }]);
        }
    }


    const handleSubmit = () => {
        if(ipAddress && domainName && startDate && endDate && selectedCustomer && selectedProduct){
           
        console.log(selectedCustomer, selectedProduct, selectedFeatures);
        console.log(ipAddress, domainName, startDate, endDate);
    
        // Convert Date objects to string format
        const activationDate = startDate instanceof Date ? startDate.toISOString() : new Date(startDate).toISOString();
    const expirationDate = endDate instanceof Date ? endDate.toISOString() : new Date(endDate).toISOString();
    
        const postData = {
            customerId: selectedCustomer.customerID,
            productId: selectedProduct.productId,
            ip: ipAddress,
            domain: domainName,
            activationDate: activationDate, // Use string format
            expirationDate: expirationDate, // Use string format
            heartBeatIntervalDays: heartBeatIntervalDays
        };
         console.log("pppppppppp",postData)
        axios.post('http://34.195.147.35:5000/api/License', postData)
            .then(response => {
                console.log('License created successfully:', response.data);
                setFeatureSwap(true);
                // Redirect or perform any other action after successful submission
            })
            .catch(error => {
                alert("Validation Error !  IP not posted")
                console.error('Error creating license:', error);
            });
        }
        else{
            alert("Please fill all the fields !! ")
        }
    }
    

            const handleFeatureSubmit = () => {

              if(filteredFeatures.length === selectedFeatures.length) {
                const postData = selectedFeatures.map(feature => ({
                    customerId: selectedCustomer.customerID,
                    productId: selectedProduct.productId,
                    featureId: feature.featureId,
                    featureValue: feature.propertyValue || '' // Use the propertyValue of the feature or an empty string if it's undefined
                }));
            
                axios.post('http://34.195.147.35:5000/api/CustomerProductValue', postData)
                    .then(response => {
                        console.log('Customer product values submitted successfully:', response.data);
                        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'License created successfully', life: 2000 });
                        setTimeout(()=>{
                            navigate("/licenseAdmin");
                        },1500)
                        
                    })
                    .catch(error => {
                        toast.current.show({ severity: 'info', summary: 'Rejected', detail: ' License not created ', life: 2000 });
                        console.error('Error submitting customer product values:', error);
                    });
              } 
              else{
                 alert("Please fill all property value fields")
              }
                
            }
          
            const getCurrentDate = () => {
                const today = new Date();
                let dd = today.getDate();
                let mm = today.getMonth() + 1; // January is 0!
                const yyyy = today.getFullYear();
            
                if (dd < 10) {
                    dd = '0' + dd;
                }
            
                if (mm < 10) {
                    mm = '0' + mm;
                }
            
                return yyyy + '-' + mm + '-' + dd;
            };

    return ( 
        <div> 
              <Toast ref={toast} />
             <h1 className="Create_Header" > 
        <Link to="/licenseAdmin" className=".for_pointer" style={{color:"white",textDecoration:"none"}} > <i className="pi pi-arrow-left  back_arrow" style={{ fontSize: '1.5rem' }}></i> </Link> Create a license </h1>
            
            <div className="flexing_mapping">

            <div className="product_mapping">  
            <h3>Select Customer</h3>
            {/* <select onChange={handleCustomerChange}>
                <option value="">Select Customer</option>
                {customerData.map(customer => (
                    <option key={customer.customerID} value={customer.customerID}>{customer.customerName}</option>
                ))}
            </select> */}
            { customerData!==null && customerData.map((customer) => {
    return (
        <div key={customer.customerID} className="flex align-items-center">
            <RadioButton inputId={customer.customerID} name="category" value={customer.customerID} onChange={handleCustomerChange} checked={selectedCustomer.customerID === customer.customerID} disabled={featureswap} />
            <label htmlFor={customer.customerID} className="ml-2 forSpace">{customer.customerName}</label>
        </div>
    );
})}  
             </div> 


            <div className="product_mapping">  
        <h3> Select Products</h3>
        {productData.map((product) => {
    return (
        <div key={product.productId} className="flex align-items-center">
            <RadioButton inputId={product.productId} name="category" value={product.productId} onChange={handleProductChange} checked={selectedProduct.productId === product.productId} disabled={featureswap} />
            <label htmlFor={product.productId} className="ml-2 forSpace">{product.productName}</label>
        </div>
    );
})}  
            </div>


            {featureswap=== true ?   
            <div className="product_mapping">
            <h3>Select Features</h3>
            {filteredFeatures.map((nestedObject, index) => {
                const featureId = nestedObject.featureId;
                const featureName = nestedObject.featureName;

                return (
                    <div key={index} className="flex align-items-center" id="checkbox_mapping">
                        {/* <input
                            type="checkbox"
                            id={featureId}
                            name={featureId.toString()} // Use featureId as the name
                            value={featureId}
                            onChange={handleFeatureChange}
                            checked={selectedFeatures.some(feature => feature.featureId === featureId)} // Check if the feature is selected
                        />
                        <label htmlFor={featureId} className="ml-2 forSpace">{featureName}</label> */}

<li key={featureId} className="forSpace">
                {featureName}
            </li>
            
                        <input
                            
                            id="featureValueInput"
                            type="text"
                            placeholder=" property value"
                            value={selectedFeatures.find(feature => feature.featureId === featureId)?.propertyValue || ''} 
                            onChange={handleFeatureChange}
                            name={featureId.toString()} // Use featureId as the name
                        />
                    </div>
                );
            })}
        </div> : " "}

</div>

{/* ----------------------------------------------------------------------------------------------------------------- */}
{/* --------------------------------------------------------------------------------------------------------------------- */}
    {featureswap===true ? ""  : <> 
    <div id="configure_header"><h2 > Configure Product </h2>
              <button onClick={handleSubmit} className="buttonTheme addLicense_submit">Submit</button>
              </div>
             
            <div id="createLicense_configure" >

               <article>
               <FloatLabel>
    <InputText id="username" value={ipAddress} onChange={(e) => setIpAddress(e.target.value)}  />
    <label htmlFor="username"> Authorize  IP </label>
</FloatLabel>
               
                </article> 

                <article>
               <FloatLabel>
    <InputText id="username" value={domainName} onChange={(e) => setDomainName(e.target.value)}  />
    <label htmlFor="username"> Domain Name  </label>
</FloatLabel>
               
                </article> 
                     



                <article>
                {/* <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon /> */}
                   <input type="date" id="username" value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder="Start date"   min={getCurrentDate()}/>
                {/* <Calendar value={startDate} onChange={(e) => setStartDate(e.value)} dateFormat="dd/mm/yy" /> */}
                </article>

                <article>
                <input type="date" id="username" value={endDate} onChange={(e) => setEndDate(e.target.value)} min={getCurrentDate()}/>
                {/* <Calendar value={endDate} onChange={(e) => setEndDate(e.value)} showIcon dateFormat="dd/mm/yy" /> */}
                </article>

                
           
               
            </div>
    </> }
             
             {featureswap===true ? <div className="addfeature_Submit"> <button className="buttonTheme addLicense_submit" onClick={handleFeatureSubmit} > Submit Feature </button> </div>  : "  "}
            
        </div>
    );
}

export default AddLicense;
