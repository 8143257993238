import React, { useState, useEffect } from "react";
import "./systemAdmin.css";
import Header from "../Header/header";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import * as XLSX from 'xlsx'; // Import XLSX library
import { useNavigate } from "react-router-dom";

const SystemAdmin = () => {
  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [id, setId] = useState(2);
  const [newUser, setNewUser] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(0);
  const [localVisible, setLocalVisible] = useState(false);
  const [showDragDrop, setShowDragDrop] = useState(false); // State to control the visibility of drag and drop area
  const navigate = useNavigate();

  useEffect(() => {
    const currentURL = window.location.href;
    const ll = localStorage.getItem("roleCALI");
    console.log("llllllllll", currentURL, ll);
    if (ll && currentURL.includes(ll)) {
      setLocalVisible(true);
    } else {
      navigate("/error")
    }
  }, [setLocalVisible]);

  const handleAdd = () => {
    setId(id + 1);
    setEdit(false);
    const userData = {
      id: id,
      username: username,
      email: email,
      role: role
    };
    console.log('Submitted Data ---------------', userData);
    setNewUser([...newUser, userData]);
    setUsername("");
    setEmail("");
    setRole("");
    setVisible(false);
  }

  const editHandle = (editItem) => {
    setEdit(true);
    setVisible(true);
    setUsername(editItem.username);
    setEmail(editItem.email);
    setRole(editItem.role);
    setId(editItem.id);
    setEditIndex(editItem.id);
  }

  const updateHandler = () => {
    let arr = []
    for (let i = 0; i < newUser.length; i++) {
      if (newUser[i].id === editIndex) {
        arr.push({ id: editIndex, username: username, email: email, role: role })
      } else {
        arr.push(newUser[i])
      }
    }
    setNewUser(arr);
    setEdit(false);
    setVisible(false);
  }

  const delHandle = (delItem) => {
    const newList = newUser.filter((data) => {
      return data.id !== delItem.id
    });
    setNewUser(newList);
  }

  const setAdd = () => {
    setEmail("");
    setUsername("");
    setRole("");
    setVisible(true);
  }

 const handleFileUpload = (event) => {
    setShowDragDrop(false)
    const file = event.target.files[0];
    readFile(file);
  };  

  const handleFileDrop = (event) => {
    event.preventDefault();
    setShowDragDrop(false); // Hide the drag and drop area
    const file = event.dataTransfer.files[0];
    readFile(file);
  };


  const readFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const newData = jsonData.slice(1).map((row, index) => ({
        id: row[0],
        username: row[1],
        email: row[2],
        role: row[3]
      }));

      setNewUser([...newUser, ...newData]);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      {localVisible ?
        <div>
          <Header />
          <section className="user-management">
            <section id="systemAdmin_button">
              <h2>User Management</h2>
              <button label="Add User" className="buttonTheme Add_user"  style={{ cursor: "pointer" }} onClick={setAdd}> Add User </button>
             
              <button label="Drag & Drop" className="drag-drop-button buttonTheme Add_user"  onClick={() => setShowDragDrop(true)} > Upload Users </button>
            </section>
            <table className="systemAdmin_table">
              <tbody>
                <tr>
                  <th>User ID</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
                <tr> 
                   <td>1</td>
                   <td>Akash</td>
                   <td>akash@gmail.com</td>
                   <td>User</td>
                   <td>
                        <button className="editUserBtn buttonTheme" >Edit</button>
                        <button className="resetPasswordBtn buttonTheme">Reset</button>
                        <button className="deleteUserBtn buttonTheme" >Delete</button>
                      </td>
                 </tr>
                {newUser.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{value.id}</td>
                      <td>{value.username}</td>
                      <td>{value.email}</td>
                      <td>{value.role}</td>
                      <td>
                        <button className="editUserBtn buttonTheme" onClick={() => { editHandle(value) }}>Edit</button>
                        <button className="resetPasswordBtn buttonTheme">Reset</button>
                        <button className="deleteUserBtn buttonTheme" onClick={() => { delHandle(value) }}>Delete</button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </section>
          <section className="audit-reports">
            <h2 id="audit">Audit Reports</h2>
            <table>
              <thead>
                <tr>
                  <th>Timestamp</th>
                  <th>User</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2024-04-20 10:15:22</td>
                  <td>john_doe</td>
                  <td>Login</td>
                </tr>
              </tbody>
            </table>
          </section>
          <div></div>
          <div>
            <Dialog header={<React.Fragment> Add user <i className="pi pi-times cross_icon" onClick={() => setVisible(false)}> </i> </React.Fragment>}
              visible={visible}
              style={{ width: '35vw' }}
              onHide={() => setVisible(false)}
              id="popup_box"
            >
              <div id="userForm">
                <input type="hidden" id="userId" name="userId" value={id} />
                <label htmlFor="username">Username:</label>
                <input type="text" id="username" name="username" onChange={(e) => { setUsername(e.target.value) }} value={username} required />
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" onChange={(e) => { setEmail(e.target.value) }} value={email} required />
                <label htmlFor="role">Role:</label>
                <select id="role" name="role" onChange={(e) => { setRole(e.target.value) }} value={role} required>
                  <option></option>
                  <option value="Administrator">Administrator</option>
                  <option value="Manager">Manager</option>
                  <option value="User">User</option>
                </select>
                {edit ? <Button className="add_update_button" onClick={updateHandler}>Update</Button> : <Button className="add_update_button" type="submit" id="submitUserBtn" onClick={handleAdd}>Add User</Button>}
              </div>
            </Dialog>
          </div>
          <div className="button-container">
          
           
          </div>
             
          <Dialog header={<React.Fragment> Upload file here <i className="pi pi-times cross_icon" onClick={() => setShowDragDrop(false)}> </i> </React.Fragment>}
              visible={showDragDrop}
              style={{ width: '35vw' }}
              onHide={() => setShowDragDrop(false)}
              id="popup_box"
            >
                   <input type="file" id="fileInput" accept=".xlsx,.xls" onChange={handleFileUpload} style={{ display: 'none' }} />
          <div className="drag-drop-popup" style={{ display: showDragDrop ? 'block' : 'none' }}>
            <div className="drag-drop-box" onDragOver={(e) => e.preventDefault()} onDrop={handleFileDrop}>
              <p> drag and drop to post data  <br/>
               <h3>OR</h3>  
              <button label="Choose File" className="choose-file-button buttonTheme Add_user"   onClick={() => document.getElementById('fileInput').click()} > Browse File </button>
                 </p>
            </div>
            {/* <Button label="Submit" className="drag_submit-button" onClick={() => setShowDragDrop(false)} /> */}
          </div>
              </Dialog>

         
        </div> :
        ""
      }
    </>
  )
}
export default SystemAdmin;
