import React from 'react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import {BrowserRouter,Routes,Route} from "react-router-dom"

import Login from './Components/Login/login';
import Executive from './Components/Executive/executive';
import SystemAdmin from './Components/SystemAdmin/systemAdmin';
import LicenseAdmin from './Components/LicenseAdmin/licenseAdmin';

import AddProducts from './Components/LicenseAdmin/AddProduct';
import AddCustomer from './Components/LicenseAdmin/AddCustomer';
import ManageFeatures from './Components/LicenseAdmin/AddFeatures';
import Error from './Components/PageError';
import Mapping from './Components/LicenseAdmin/MappingPF';
import AddLicense from './Components/LicenseAdmin/AddLicense';
function App() {
  return (
    <>
          <BrowserRouter>
            <Routes>
                  <Route path="/" element={<Login/> } />
                  <Route path="/executive" element={<Executive/> } />
                  <Route path="/systemAdmin" element={<SystemAdmin/> } />
                  <Route path="/licenseAdmin" element={<LicenseAdmin/> } />      
                  <Route path="/licenseAdmin/manageProduct" element={<AddProducts/> } />
                  <Route path="/licenseAdmin/manageCustomer" element={<AddCustomer/> } />
                  <Route path="/licenseAdmin/manageFeature" element={<ManageFeatures/> } />
                  <Route path="/licenseAdmin/mapping" element={<Mapping/>} />
                  <Route path="/licenseAdmin/manageLicense" element={<AddLicense/>} />
                  <Route path="/error" element={<Error/> } />
            </Routes>
           </BrowserRouter>
    </>
  );
}

export default App;



